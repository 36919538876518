import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"

const Main = ({ pageContext, data }) => (
  <Layout>
    <h1>{data.contentfulMain.title}</h1>
  </Layout>
)

export const query = graphql `
  query ContentFulMain($slug: String, $locale: String) {
    contentfulMain(path: { eq: $slug }, node_locale: { eq: $locale }) {
      path
      node_locale
      title
    }
  }
`

export default Main
